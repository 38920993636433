import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import Prompter from './prompter/prompter';
import EvaluareLucrari from './evaluare-lucrari/evaluare-lucrari';

import './apps.css';

const Apps = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/prompter`}>
        <Prompter />
      </Route>
      <Route path={`${path}/evaluare-lucrari`}>
        <EvaluareLucrari />
      </Route>
      <Route>
        <Redirect to={{ pathname: '/' }} />
      </Route>
    </Switch>
  );
};

export default Apps;