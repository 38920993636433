import './footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear().toString();

  return (
    <footer className="footer">
      <p className="footer__links">
        <a href="#about-me">About me</a>|<a href="#cv">CV</a>|
        <a href="#portfolio">Portfolio</a>|
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/olaru-razvan-catalin-83132965/"
        >
          LinkedIn
        </a>
      </p>
      <p>
        Copyright &copy; Răzvan Olaru -{' '}
        {currentYear !== '2020' ? '2020-' + currentYear : currentYear}
      </p>
      <h4>Credits:</h4>
      <div>
        Icons made by{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.flaticon.com/authors/smashicons"
          title="Smashicons"
        >
          Smashicons
        </a>{' '}
        from{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.flaticon.com/"
          title="Flaticon"
        >
          www.flaticon.com
        </a>
      </div>
      <div>
        Icons made by{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.flaticon.com/authors/pixel-perfect"
          title="Pixel perfect"
        >
          Pixel perfect
        </a>{' '}
        from{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.flaticon.com/"
          title="Flaticon"
        >
          www.flaticon.com
        </a>
      </div>
      <div>
        Icons made by{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.flaticon.com/authors/freepik"
          title="Freepik"
        >
          Freepik
        </a>{' '}
        from{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.flaticon.com/"
          title="Flaticon"
        >
          www.flaticon.com
        </a>
      </div>
    </footer>
  );
};

export default Footer;
