import Header from './../../components/header/header';
import Footer from './../../components/footer/footer';
import Bubbles from './../../components/bubbles/bubbles';
import About from './about/about';
import CV from './cv/cv';
import Portfolio from './portfolio/portfolio';

import { useLoadAnimations } from './main-website.effects';

const MainWebsite = () => {
  useLoadAnimations();

  return [
    <Header key="header"></Header>,
    <main key="content" className="content">
      <Bubbles />
      <About></About>
      <CV></CV>
      <Portfolio></Portfolio>
    </main>,
    <Footer key="footer"></Footer>
  ];
}

export default MainWebsite;