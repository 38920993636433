import './bubbles.css';

const Bubbles = () => {
  return (
    <div className="bubbles-wrapper">
      <div className="fab fa-angular bubble angular-bubble"></div>
      <div className="fab fa-react bubble react-bubble"></div>
      <div className="fab fa-node bubble nodejs-bubble"></div>
    </div>
  );
};

export default Bubbles;
