import { useEffect, useState } from 'react';

import './progress-bar.css';

const ProgressBar = (props) => {
  const [progress, setProgress] = useState(0);

  useEffect(
    () =>
      setTimeout(() => {
        setProgress(props.fill);
      }, 10),
    [props.fill]
  );

  return (
    <div className="progress-bar">
      <div
        className={
          'progress-bar__fill' +
          (props.manualAnimation ? ' force-animation' : '')
        }
        style={{ width: progress + '%' }}
      ></div>
    </div>
  );
};

export default ProgressBar;
