import ProgressBar from './../../../components/progress-bar/progress-bar';

import pdfCv from './../../../assets/documents/Europass-CV-Olaru-EN.pdf';
import wordCv from './../../../assets/documents/Europass-CV-Olaru-EN.docx';

import './cv.css';

const CV = () => {
  return (
    <section id="cv" className="section cv">
      <div className="section__floating-bg cv__floating-bg"></div>
      <div className="section__content">
        <h2>CV</h2>
        <div className="cv__view-options">
          <a
            className="button pdf"
            rel="noreferrer"
            target="_blank"
            href={pdfCv}
          >
            PDF
          </a>
          <a className="button word" href={wordCv}>
            Word
          </a>
          <a
            className="button linkedin"
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/olaru-razvan-catalin-83132965/"
          >
            LinkedIn
          </a>
        </div>
        <div className="cv__short-version">
          <div className="cv__education-and-companies">
            <h3>Jobs</h3>
            <ul>
              <li>
                <strong>Freelancer at Olaru Răzvan Web Solutions</strong>
                <br />
                <small>(February 2022 - present)</small>
                <p className="job-title">
                  Senior full stack freelance web developer (Angular 12+, React,
                  Storybook, Atomic Design, design systems)
                </p>
              </li>
              <li>
                <strong>Ness Digital Engineering</strong>{' '}
                <small>(July 2019 - present)</small>
                <p className="job-title">
                  Senior front-end web developer (Angular 7+, NgRx and React)
                </p>
              </li>
              <li>
                <strong>3Pillar Global</strong>{' '}
                <small>(December 2015 - July 2019)</small>
                <p className="job-title">
                  Senior full-stack web developer (React, Node.js, Express,
                  PostgresSQL)
                </p>
              </li>
              <li>
                <strong>Sofisto Consulting</strong>{' '}
                <small>(July 2014 - October 2015)</small>
                <br />
                <p className="job-title">
                  Smart TV &amp; Set-Top Box developer (Vanilla JS, HTML, CSS)
                </p>
              </li>
              <li>
                <strong>Blue Couch Digital</strong>{' '}
                <small>(May 2013 - August 2013)</small>
                <p className="job-title">
                  Junior web developer (Backbone, HTML, CSS)
                </p>
              </li>
            </ul>
            <h3>Education</h3>
            <ul>
              <li>
                Polytechnic Univeristy of Timișoara <small>(2011-2014)</small>
              </li>
              <li>
                "Ecaterina Teodoroiu" High School{' '}
                <small>(finished in 2011)</small>
              </li>
            </ul>
          </div>
          <div className="cv__knowledge">
            <h3>Knowledge</h3>
            <ul>
              <li>
                JavaScript (ES5 - ES10)
                <ProgressBar manualAnimation={true} fill={95} />
              </li>
              <li>
                React
                <ProgressBar manualAnimation={true} fill={95} />
              </li>
              <li>
                Redux
                <ProgressBar manualAnimation={true} fill={100} />
              </li>
              <li>
                Angular
                <ProgressBar manualAnimation={true} fill={95} />
              </li>
              <li>
                Node.js &amp; Express
                <ProgressBar manualAnimation={true} fill={75} />
              </li>
              <li>
                SQL
                <ProgressBar manualAnimation={true} fill={75} />
              </li>
              <li>
                MongoDB / Mongoose
                <ProgressBar manualAnimation={true} fill={70} />
              </li>
              <li>
                HTML5
                <ProgressBar manualAnimation={true} fill={90} />
              </li>
              <li>
                CSS / SASS / LESS
                <ProgressBar manualAnimation={true} fill={95} />
              </li>
              <li>
                Software architecture
                <ProgressBar manualAnimation={true} fill={85} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CV;
