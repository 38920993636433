import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ElClassDialog = ({ isOpen, classToEdit, onClose }) => {
  const [className, setClassName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      setClassName('');
      setError('');
    }
  }, [isOpen]);

  useEffect(() => {
    setClassName(classToEdit?.name || '');
  }, [classToEdit]);

  const onChangeName = (event) => setClassName(event.target.value);

  const onCancel = () => onClose();

  const onSubmit = () => {
    if (className) {
      onClose(className);
    } else {
      setError('Numele este obligatoriu');
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={isOpen} onClose={onCancel}>
      <DialogTitle>{classToEdit ? 'Editeză clasa' : 'Adaugă o clasă'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          margin="dense"
          label="Nume clasă"
          type="text"
          variant="standard"
          error={!!error}
          helperText={error}
          value={className}
          onChange={onChangeName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Anulare</Button>
        <Button onClick={onSubmit}>{classToEdit ? 'Salvare' : 'Creare'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ElClassDialog;
