import { useRef } from 'react';

import {
  useLargeViewNavigationControls,
  useLargeViewZoomControls,
} from './image-large-view.effects';

import './image-large-view.css';

const ImageLargeView = (props) => {
  const imageRef = useRef(null);

  const { imageIndex, imageTransition, onPreviousImage, onNextImage } =
    useLargeViewNavigationControls(props.images, props.currentIndex);

  const { onZoomIn, onZoomOut } = useLargeViewZoomControls(
    imageRef,
    imageIndex
  );

  const image = props.images[imageIndex];

  return (
    <div className="image-large-view">
      <button
        type="button"
        className=" close-button"
        onClick={props.closeLargeView}
      >
        <i className="fas fa-times"></i>
      </button>
      <div className="image-preview">
        <button
          className={props.images.length === 1 ? 'disabled' : ''}
          onClick={onPreviousImage}
        >
          <i className="fa fa-chevron-left"></i>
        </button>
        <div
          className={
            'image-preview__container' +
            (props.images.length === 1 ? ' single-image ' : '')
          }
        >
          <img
            ref={imageRef}
            className={imageTransition ? 'fade-out' : 'fade-in'}
            alt={image.alt}
            src={image.src}
          />
        </div>
        <button
          className={props.images.length === 1 ? ' disabled' : ''}
          onClick={onNextImage}
        >
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
      <div className="image-large-view__zoom-buttons">
        <button className="zoom-out-button" onClick={onZoomOut}>
          <i className="fa fa-search-minus"></i>
        </button>
        <button className="zoom-in-button" onClick={onZoomIn}>
          <i className="fa fa-search-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default ImageLargeView;
