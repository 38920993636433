import { useState } from 'react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import ElItemsList from './../components/el-items-list/el-items-list';
import ElClassDialog from './../components/el-class-dialog/el-class-dialog';

import { Actions } from './../evaluare-lucrari.store';

const ElClassesPage = ({ classes, dispatch }) => {
  const [isClassDialogOpen, setIsClassDialogOpen] = useState(false);
  const [classToEdit, setClassToEdit] = useState(null);

  const onShowAddClassDialog = () => {
    setIsClassDialogOpen(true);
    setClassToEdit(null);
  };

  const onShowClassEditDialog = (changedClass) => {
    setIsClassDialogOpen(true);
    setClassToEdit(changedClass);
  };

  const onRemoveClass = (classToRemove) => {
    if (window.confirm(`Sigur doriți să ștergeți clasa "${classToRemove.name}"?`)) {
      dispatch(Actions.removeClass(classToRemove.id));
    }
  };

  const onCloseTestModal = (className) => {
    if (className) {
      if (classToEdit) {
        dispatch(Actions.editClass(classToEdit.id, className));
      } else {
        dispatch(Actions.createNewClass(className));
      }
    }

    setClassToEdit(null);
    setIsClassDialogOpen(false);
  };

  return (
    <main className="page el-classes-page">
      <h2>Clase</h2>

      {classes.length > 0 ? (
          <ElItemsList
            items={classes}
            onEditItem={onShowClassEditDialog}
            onRemoveItem={onRemoveClass}
          />
        ) : (
          <p>Nu a fost adăugată nici o clasă.</p>
        )
      }

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={onShowAddClassDialog}
      >
        Adaugă o clasă nouă
      </Button>

      <ElClassDialog
        isOpen={isClassDialogOpen}
        testToEdit={classToEdit}
        onClose={onCloseTestModal}
      />
    </main>
  );
};

export default ElClassesPage;