import ImageSlider from './../../../components/image-slider/image-slider';
import ImageWithLargeView from './../../../components/image-with-large-view/image-with-large-view';

import lexisNexis1 from './../../../assets/images/LexisNexis/LexisNexis1.jpg';
import lexisNexis2 from './../../../assets/images/LexisNexis/LexisNexis2.jpg';

import homecare1 from './../../../assets/images/Homecare/homecare1.webp';
import homecare2 from './../../../assets/images/Homecare/homecare2.webp';
import homecare3 from './../../../assets/images/Homecare/homecare3.webp';
import homecare4 from './../../../assets/images/Homecare/homecare4.webp';

import umpgLandingPage from './../../../assets/images/UMPG/umpg-landing-page.png';
import umpgMusicLibrary from './../../../assets/images/UMPG/umpg-music-library.png';
import umpgSongDetails from './../../../assets/images/UMPG/umpg-song-details.png';
import umpgArtistsPage from './../../../assets/images/UMPG/umpg-artists-page.png';
import umpgIndividualArtistPage from './../../../assets/images/UMPG/umpg-individual-artist-page.png';

import nutrikiddoScreenRecording from './../../../assets/videos/Nutrikiddo/screen-recording-nutrikiddo.mp4';

import andradaBusescuWebsite from './../../../assets/images/Andradabusescu.ro/andradabusescu.ro.png';

const toggleProjectDetails = (e) => {
  const toggleButton = e.target;
  const projectElement = toggleButton.parentElement;

  projectElement.classList.toggle('expanded');
  toggleButton.innerHTML = projectElement.classList.contains('expanded')
    ? 'Less'
    : 'More';
};

const portfolioSlides = [
  {
    key: 'homecare-slide',
    content: (
      <div className="portfolio__project homecare">
        <h3>Homecare</h3>
        <p className="website-link">
          (
          <a rel="noreferrer" target="_blank" href="https://www.homecare.com/">
            Visit website <i className="fa fa-external-link-alt"></i>
          </a>
          )
          <br />(
          <a
            rel="noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.homecare.caregiverapp&hl=ro"
          >
            HomeCare mobile app <i className="fa fa-external-link-alt"></i>
          </a>
          )
        </p>
        <p>
          HomeCare is a platform for connecting certified caregivers to families
          that need qualified caregiving services for one or more family
          members.
          <br />
          <strong>We worked on three main projects for them:</strong>
        </p>
        <ul>
          <li>
            <strong>the website</strong> (dedicated to families),
          </li>
          <li>
            <strong>the platform for managing caregivers</strong> (for the
            HomeCare staff),
          </li>
          <li>
            <strong>the mobile app</strong> (for caregivers to seek jobs and
            track time - screenshots below)
          </li>
        </ul>
        <ImageSlider
          verticalImages={true}
          images={[
            { _id: '2ed577fca', alt: 'HomeCare', src: homecare1 },
            { _id: '2ed577fcb', alt: 'HomeCare', src: homecare2 },
            { _id: '2ed577fcc', alt: 'HomeCare', src: homecare3 },
            { _id: '2ed577fcd', alt: 'HomeCare', src: homecare4 },
          ]}
        />
        <h4>Technologies used:</h4>
        <ul>
          <li>
            <strong>Front-end:</strong> Cordova, React, Redux, HTML5, SASS, CSS3
          </li>
          <li>
            <strong>Back-end:</strong> Node.js, Express, PostgresSQL
          </li>
        </ul>
        <button className="expand-button" onClick={toggleProjectDetails}>
          More
        </button>
      </div>
    ),
  },
  {
    key: 'umg-slide',
    content: (
      <div className="portfolio__project umg">
        <h3>Universal Music Publishing Group</h3>
        <p className="website-link">
          (
          <a rel="noreferrer" target="_blank" href="https://www.umusicpub.com/">
            Visit website <i className="fa fa-external-link-alt"></i>
          </a>
          )
        </p>
        <p>
          Universal Music Publishing is the world's second largest music
          publishing company. UMPG's catalogue consists of over three million
          songs and their record labels promote many of the worlds greatest
          artists including Michael Jackson, Janet Jackson, Shania Twain, Bon
          Jovi, Elton John, Tupac Shakur, Jay-Z, Mariah Carey, t.A.T.u.,
          Rammstein and many others.
        </p>
        <ImageSlider
          verticalImages={true}
          images={[
            {
              _id: '2ed578fba',
              alt: 'UMPG landing page',
              src: umpgLandingPage,
            },
            {
              _id: '2ed578fbb',
              alt: 'UMPG music library search',
              src: umpgMusicLibrary,
            },
            {
              _id: '2ed578fbc',
              alt: 'UMPG song details page',
              src: umpgSongDetails,
            },
            {
              _id: '2ed578fbd',
              alt: 'UMPG artists page',
              src: umpgArtistsPage,
            },
            {
              _id: '2ed578fbe',
              alt: 'UMPG individual artist page',
              src: umpgIndividualArtistPage,
            },
          ]}
        />
        <h4>Technologies used:</h4>
        <ul>
          <li>
            <strong>Front-end:</strong> Angular 7+, NgRx, SASS, HTML5, CSS3
          </li>
          <li>
            <strong>Unit testing:</strong> Jasmine (included in the Angular
            framework)
          </li>
        </ul>
        <button className="expand-button" onClick={toggleProjectDetails}>
          More
        </button>
      </div>
    ),
  },
  {
    key: 'lexisnexis-project',
    content: (
      <div className="portfolio__project lexisnexis">
        <h3>LexisNexis Entity Insight</h3>
        <p className="website-link">
          (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.lexisnexis.com/en-us/professional/risk-management/entity-insight.page"
          >
            Visit website <i className="fa fa-external-link-alt"></i>
          </a>
          )
        </p>
        <p>
          LexisNexis Entity Insight is a business risk monitoring product which
          gives the user a high degree of customization using various views,
          filters, search options and charts. One of it's most prominent
          features is the risk assessment using the{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://pestleanalysis.com/what-is-pestle-analysis/"
          >
            PESTLE analysis
          </a>
          .
        </p>
        <ImageSlider
          images={[
            {
              _id: '2ed577fae',
              alt: 'LexisNexis Entity Insight landing page',
              src: lexisNexis1,
            },
            {
              _id: '2ed577fab',
              alt: 'LexisNexis Entity Insight custom view',
              src: lexisNexis2,
            },
          ]}
        />
        <h4>Technologies used:</h4>
        <ul>
          <li>
            <strong>Front-end:</strong> React, Redux, HTML5, SASS with BEM, CSS3
          </li>
          <li>
            <strong>Unit testing:</strong> Mocha, Chai, Sinon, Enzyme and
            Istanbul.js (code-coverage)
          </li>
        </ul>
        <button className="expand-button" onClick={toggleProjectDetails}>
          More
        </button>
      </div>
    ),
  },
  {
    key: 'nutrikiddo-slide',
    content: (
      <div className="portfolio__project nutrikiddo">
        <h3>NutriKiddo</h3>
        <p className="website-link">
          <strong>
            (App will be published to app store soon, please see video below
            instead)
          </strong>
        </p>
        <div className="columns-content">
          <div className="content-left">
            <p>
              NutriKiddo is a personal project which aids parents with all the
              tools they need for taking care of their newborn's health. The app
              offers them:
            </p>
            <ul>
              <li>
                An articles feed with custom content tailored for their child
                (depending on sex, age, weight, height, etc.),
              </li>
              <li>
                A journal where they can track the activities done with their
                child (height/weight measurements, diaper changes, stools,
                sleep, etc.),
              </li>
              <li>
                A product scan or search to see if the product contains the
                right nutrients for their child,
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.cdc.gov/growthcharts/clinical_charts.htm"
                >
                  WHO/CDC growth charts
                </a>{' '}
                for weight, height and age
              </li>
            </ul>
            <h4>Technologies used:</h4>
            <ul>
              <li>
                <strong>Front-end:</strong> Cordova, React, Redux, HTML5, CSS3
              </li>
              <li>
                <strong>Back-end:</strong> Node.js, Express, MongoDB, Mongoose
              </li>
            </ul>
          </div>
          <div className="content-right">
            <video controls src={nutrikiddoScreenRecording}></video>
          </div>
        </div>
        <button className="expand-button" onClick={toggleProjectDetails}>
          More
        </button>
      </div>
    ),
  },
  {
    key: 'andradabusescu-slide',
    content: (
      <div className="portfolio__project andradabusescu">
        <h3>Andrada Bușescu dentist office</h3>
        <p className="website-link">
          (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.andradabusescu.ro/"
          >
            Visit website <i className="fa fa-external-link-alt"></i>
          </a>
          )
        </p>
        <div className="columns-content">
          <div className="content-left">
            <p>
              This is a presentation website for my dentist (Andrada Bușescu) in
              which patients can see her services, prices, location and even
              make appointments using a customized calendar.
            </p>
            <h4>Technologies used:</h4>
            <ul>
              <li>
                <strong>Front-end:</strong> HTML5, CSS3 (for customizing the
                WordPress theme)
              </li>
              <li>
                <strong>CMS:</strong> WordPress (also wrote a bit of PHP to
                customize the calendar plugin)
              </li>
            </ul>
          </div>
          <div className="content-right">
            <ImageWithLargeView
              loading="lazy"
              alt="Andrada Bușescu dentist office website"
              src={andradaBusescuWebsite}
            />
          </div>
        </div>
        <button className="expand-button" onClick={toggleProjectDetails}>
          More
        </button>
      </div>
    ),
  },
];

export default portfolioSlides;
