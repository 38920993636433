import ReactDOM from 'react-dom';

import ImageLargeView from './../image-large-view/image-large-view';

const modalContainerElement = document.getElementById('modal-container');

const ImageWithLargeView = (props) => {
  const { alt: imgAlt, ...otherProps } = props;

  const onCloseLargeView = () => ReactDOM.render(null, modalContainerElement);

  /**
   * In order to avoid a classic issue that Chrome has with "position: fixed" elements
   * inside parents with "transform: translate" we will place the large image view overlay
   * in the "modal-container" div.
   *
   * {@link https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform}
   */
  const onOpenLargeView = () =>
    ReactDOM.render(
      <ImageLargeView
        images={[{ ...props }]}
        currentIndex={0}
        closeLargeView={onCloseLargeView}
      />,
      modalContainerElement
    );

  return (
    <img
      style={{ cursor: 'pointer' }}
      alt={imgAlt}
      {...otherProps}
      onClick={onOpenLargeView}
    />
  );
};

export default ImageWithLargeView;
