import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './prompter.css';

const fonts = [
  'Arial',
  'Verdana',
  'Helvetica',
  'Times New Roman',
  'Courier New'
];
const fontSizes = [ 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 40, 48, 72, 96 ];
const themes = [ 'light', 'dark' ];

const entireScreenScrollBias = 100;

let startTimeoutHandler;
let startCountdownIntervalHandler;
let scrollIntervalHandler;
let onStopTimeoutHandler;
let manualScrollTimeoutHandler;
let savedSettings;

const parseKey = value => value.replace(' ', '-').toLowerCase();


const Prompter = () => {
  const [ font, setFont ] = useState(fonts[0]);
  const [ fontSize, setFontSize ] = useState(fontSizes[14]);
  const [ startDelay, setStartDelay ] = useState(5);
  const [ scrollSpeed, setScrollSpeed ] = useState(1);
  const [ scrollEntireScreenAtOnce, setScrollEntireScreenAtOnce ] = useState(false);
  const [ viewMargins, setViewMargins ] = useState(8);
  const [ text, setText ] = useState('');
  const [ theme, setTheme ] = useState(themes[1]);
  const [ countdown, setCountdown ] = useState(0);
  const [ isStarted, setIsStarted ] = useState(false);

  const prompterRef = useRef(null);
  const prompterViewContentRef = useRef(null);
  const scrollInterval = scrollEntireScreenAtOnce ? 300 * 1000 / scrollSpeed : 75;

  useEffect(() => {
    const localStorageSettings = localStorage.getItem('settings');
    savedSettings =
      localStorageSettings ?
        JSON.parse(localStorageSettings)
      :
        {
          font: fonts[0],
          fontSize: fontSizes[14],
          startDelay: 5,
          scrollSpeed: 1,
          scrollEntireScreenAtOnce: false,
          viewMargins: 8,
          theme: themes[1]
        };

    setFont(savedSettings.font);
    setFontSize(savedSettings.fontSize);
    setStartDelay(savedSettings.startDelay);
    setScrollSpeed(savedSettings.scrollSpeed);
    setScrollEntireScreenAtOnce(savedSettings.scrollEntireScreenAtOnce);
    setViewMargins(savedSettings.viewMargins);
    setTheme(savedSettings.theme);
  }, []);

  const validateNumber = (value, min, max) => {
    if (value < min) {
      return min;
    } else if (value > max) {
      return max;
    } else {
      return value;
    }
  };

  const changeSavedSettings = (property, value) => {
    savedSettings[property] = value;
    localStorage.setItem('settings', JSON.stringify(savedSettings));
  };

  const onChangeFont = e => {
    const value = e.target.value;
    setFont(value);
    changeSavedSettings('font', value);
  };

  const onChangeFontSize = e => {
    const value = e.target.value;
    setFontSize(value);
    changeSavedSettings('fontSize', value);
  };

  const onChangeScrollSpeed = e => {
    const value = validateNumber(e.target.value, 0, 20);
    setScrollSpeed(value);
    changeSavedSettings('scrollSpeed', value);
  };

  const onChangeScrollEntireScreenAtOnce = () => {
    const value = !scrollEntireScreenAtOnce;
    setScrollEntireScreenAtOnce(value);
    changeSavedSettings('scrollEntireScreenAtOnce', value);
  };

  const onChangeViewMargins = e => {
    const value = validateNumber(e.target.value, 0, 35);
    setViewMargins(value);
    changeSavedSettings('viewMargins', value);
  };

  const onChangeStartDelay = e => {
    const value = validateNumber(e.target.value, 0, 120);
    setStartDelay(value);
    changeSavedSettings('startDelay', value);
  };

  const onChangePrompterText = e => setText(e.target.value);

  const onChangeTheme = e => {
    const value = e.target.value;
    setTheme(value);
    changeSavedSettings('theme', value);
  };

  const scrollContents = () => {
    const prompterView = prompterViewContentRef.current;
    const speed = parseInt(scrollSpeed) || 1;
    const scrollAmount = scrollEntireScreenAtOnce ? (window.innerHeight - entireScreenScrollBias) : speed;

    if (prompterView.clientHeight + prompterView.scrollTop + scrollAmount < prompterView.scrollHeight) {
      prompterView.scrollTop = prompterView.scrollTop + scrollAmount;
    } else {
      onStopTimeoutHandler = setTimeout(() => onStop(), 3000);
      prompterView.scrollTop = prompterView.scrollHeight - prompterView.clientHeight;
      clearInterval(scrollIntervalHandler);
      scrollIntervalHandler = null;
    }
  };

  const onStart = () => {
    if (!text) {
      alert("You must instert a script text before starting the teleprompter.");
      return;
    }

    prompterRef.current.scrollTop = 0;

    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
  
    setIsStarted(true);

    if (startDelay > 0) {
      setCountdown(startDelay);
      startCountdownIntervalHandler = setInterval(() => setCountdown(c => c - 1), 1000);
    }

    // Wait a couple of seconds for the user to start reading
    startTimeoutHandler = setTimeout(() => {
      clearInterval(startCountdownIntervalHandler);
      startCountdownIntervalHandler = null;
      
      // Make sure the countdown really decremented to 0 (maybe the
      // counter interval was cleared before reaching this value)
      setCountdown(0);

      scrollIntervalHandler = setInterval(() => scrollContents(), scrollInterval);
    }, startDelay * 1000);
  };

  const onStop = () => {

    // In case the user already stopped the prompter from the close button,
    // exactly when the text finished closing and the prompter was about to close.
    if (onStopTimeoutHandler) {
      clearTimeout(onStopTimeoutHandler);
      onStopTimeoutHandler = null;
    }

    // Clear any other delayed actions for the
    // prompter view which can cause an error.

    if (startTimeoutHandler) {
      clearTimeout(startTimeoutHandler);
      startTimeoutHandler = null;
    }

    if (scrollIntervalHandler) {
      clearInterval(scrollIntervalHandler);
      scrollIntervalHandler = null;
    }

    if (manualScrollTimeoutHandler) {
      clearTimeout(manualScrollTimeoutHandler);
      manualScrollTimeoutHandler = null;
    }

    if (startCountdownIntervalHandler) {
      clearInterval(startCountdownIntervalHandler);
      startCountdownIntervalHandler = null;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    setIsStarted(false);
  };

  /**
   * While manually scrolling the prompter, disable the autoscrolling feature.
   */
  const onManualScroll = () => {

    // Manual scrolling not finished yet...
    if (manualScrollTimeoutHandler) {
      clearTimeout(manualScrollTimeoutHandler);
      manualScrollTimeoutHandler = null;
    }

    if (scrollIntervalHandler) {
      clearInterval(scrollIntervalHandler);
      scrollIntervalHandler = null;
    }

    // Check after 50ms if manual scrolling is
    // finished, in order to enable autoscrolling
    manualScrollTimeoutHandler = setTimeout(() => {
      scrollIntervalHandler = setInterval(() => scrollContents(), scrollInterval);
    }, 750);
  };

  return (
    <div ref={prompterRef} className={ "prompter app-wrapper" + (isStarted ? ' started' : '') }>
      <h1>Teleprompter</h1>
      <h2>Free versatile and easy to use teleprompter.</h2>
      <h5>By <Link to="/">Răzvan Olaru</Link></h5>
      <div className="prompter__input-row input-row">
        <label htmlFor="font">Font:</label>
        <select id="font" value={font} onChange={onChangeFont}>
          {fonts.map(font => (
            <option
              key={'font-' + parseKey(font)}
              value={font}
            >
              {font}
            </option>
          ))}
        </select>
      </div>
      <div className="prompter__input-row input-row">
        <label htmlFor="font-size">Font size:</label>
        <select id="font-size" value={fontSize} onChange={onChangeFontSize}>
          {fontSizes.map(fontSize => (
            <option key={'font-size-' + fontSize} value={fontSize}>{fontSize}</option>
          ))}
        </select>
      </div>
      <div className="prompter__input-row input-row">
        <label htmlFor="theme">Theme:</label>
        <select id="theme" value={theme} onChange={onChangeTheme}>
          {themes.map(theme => (
            <option key={'theme-' + theme} value={theme}>{theme}</option>
          ))}
        </select>
      </div>
      <div className="prompter__input-row input-row">
        <label htmlFor="scroll-speed">Scroll speed:</label>
        <input id="scroll-speed" type="number" value={scrollSpeed} onChange={onChangeScrollSpeed} />
        <span>
          &nbsp;
          { scrollEntireScreenAtOnce ? 'screens every 5 minutes' : '' }
        </span>
      </div>
      <div className="prompter__input-row input-row">
        <label htmlFor="scroll-entire-screen">Scroll entire<br />screen at once:</label>
        <input
          id="scroll-entire-screen"
          type="checkbox"
          checked={scrollEntireScreenAtOnce}
          onChange={onChangeScrollEntireScreenAtOnce}
        />
      </div>
      <div className="prompter__input-row input-row">
        <label htmlFor="start-delay">Start delay:</label>
        <input id="start-delay" type="number" value={startDelay} onChange={onChangeStartDelay} />
        <span>&nbsp;seconds</span>
      </div>
      <div className="prompter__input-row input-row">
        <label htmlFor="view-margins">View text margins<br />(left and right):</label>
        <input id="view-margins" type="number" value={viewMargins} onChange={onChangeViewMargins} />
        <span>&nbsp;%</span>
      </div>
      <div className="prompter__input-row input-row align-top">
        <label htmlFor="text">Text:</label>
        <textarea
          id="text"
          className="prompter__text-input"
          value={text}
          placeholder="Copy your script text here..."
          onChange={onChangePrompterText}
        >
        </textarea>
      </div>
      <div className="prompter__input-row input-row action-buttons-row">
        <button className="prompter__button button primary" onClick={onStart}>START</button>
      </div>

      { isStarted &&
        <div className={ "prompter__view" + (theme ? ' ' + theme : '') }>
          <div className="prompter__view-header">
            <button className="prompter__button button close" onClick={onStop}>X</button>
          </div>
          <div
            ref={prompterViewContentRef}
            className="prompter__view-content"
            style={{
              paddingLeft: `${viewMargins}%`,
              paddingRight: `${viewMargins}%`
            }}
            onWheel={onManualScroll}
            onTouchMove={onManualScroll}
          >
            <pre
              style={{
                fontFamily: font,
                fontSize: fontSize + 'px'
              }}
            >
              {text}
            </pre>
          </div>
          { countdown > 0 &&
            <div className="prompter__view-countdown">
              <div>{ countdown }</div>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default Prompter;