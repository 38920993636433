import ReactDOM from 'react-dom';
import Slider from 'react-slick';

import ImageLargeView from './../image-large-view/image-large-view';

import { useAdjustNumberOfSlides } from './image-slider.effects';

import './image-slider.css';

const modalContainerElement = document.getElementById('modal-container');

const ImageSlider = (props) => {
  const numberOfSlides = useAdjustNumberOfSlides(props.verticalImages);
  const areAllSlidesVisible = props.images.length <= numberOfSlides;

  const onCloseLargeView = () => ReactDOM.render(null, modalContainerElement);

  /**
   * In order to avoid a classic issue that Chrome has with "position: fixed" elements
   * inside parents with "transform: translate" we will place the large image view overlay
   * in the "modal-container" div.
   *
   * {@link https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform}
   */
  const onOpenLargeView = (images, currentIndex) =>
    ReactDOM.render(
      <ImageLargeView
        images={images}
        currentIndex={currentIndex}
        closeLargeView={onCloseLargeView}
      />,
      modalContainerElement
    );

  return (
    <Slider
      className={
        'image-slider' + (areAllSlidesVisible ? ' all-slides-visible' : '')
      }
      slidesToShow={numberOfSlides}
      infinite={!areAllSlidesVisible}
      dots={false}
      arrows={!areAllSlidesVisible}
    >
      {props.images.map((image, index) => (
        <img
          key={image._id}
          alt={image.alt}
          src={image.src}
          loading="lazy"
          onClick={() => onOpenLargeView(props.images, index)}
        />
      ))}
    </Slider>
  );
};

export default ImageSlider;
