import { useState, useEffect } from 'react';
import { isMobile } from './../../common/utils/utils';

import './header.css';

import profileImage from './../../assets/images/Razvan-Olaru.jpg';
import smallProfileImage from './../../assets/images/Razvan-Olaru-100x100.jpg';

const Header = () => {
  const [isMobileView, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(isMobile());

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <header className="header">
      <div className="header__logo">
        <img
          alt="Răzvan Olaru"
          src={isMobileView ? smallProfileImage : profileImage}
        />
      </div>
      <div className="header__right">
        <h1>Răzvan Olaru</h1>
        <ul className="header__main-menu">
          <li>
            <a href="#about-me">About me</a>
          </li>
          <li>
            <a href="#cv">CV</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
        </ul>
        <hr className="header__divider" />
        <h2>Full-stack web developer</h2>
        <h3>
          <i className="fa fa-quote-left"></i> Making the web a better place{' '}
          <i className="fa fa-quote-right"></i>
        </h3>
        <div className="call-to-action">
          <span>Need my services?</span>
          <a
            className="button"
            rel="noreferrer"
            target="_blank"
            href="https://www.upwork.com/freelancers/~01f7dc472f561ea13b"
          >
            Find me on Upwork
          </a>
          <a
            className="button e-mail-button"
            href="mailto:olaru.razvan.2000@gmail.com"
          >
            E-mail me
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
