import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ElTestDialog = ({ isOpen, testToEdit, onClose }) => {
  const [testName, setTestName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      setTestName('');
      setError('');
    }
  }, [isOpen]);

  useEffect(() => {
    setTestName(testToEdit?.name || '');
  }, [testToEdit]);

  const onChangeName = (event) => setTestName(event.target.value);

  const onCancel = () => onClose();

  const onSubmit = () => {
    if (testName) {
      onClose(testName);
    } else {
      setError('Numele este obligatoriu');
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={isOpen} onClose={onCancel}>
      <DialogTitle>{testToEdit ? 'Editeză testul' : 'Adaugă test'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          margin="dense"
          label="Nume test"
          type="text"
          variant="standard"
          error={!!error}
          helperText={error}
          value={testName}
          onChange={onChangeName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Anulare</Button>
        <Button onClick={onSubmit}>{testToEdit ? 'Salvare' : 'Creare'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ElTestDialog;
