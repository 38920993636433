import { useState, useEffect, useCallback } from 'react';

let changeImageTimeout = null;
let fadeInTimeout = null;

export const useLargeViewNavigationControls = (images, currentIndex) => {
    const [imageIndex, setImageIndex] = useState(currentIndex);
    const [imageTransition, setImageTransition] = useState(false);

    // useCallback to only create this event handler once and not on every re-render.
    const changeImage = useCallback(index => {

        // Clear animations in transition
        clearTimeout(changeImageTimeout);
        clearTimeout(fadeInTimeout);

        // First apply the fade-out class, change the image and then fade-in
        changeImageTimeout = setImageTransition(true);
        setTimeout(() => {
            setImageIndex(index);
            fadeInTimeout = setTimeout(() => setImageTransition(false), 25);
        }, 250);
    }, []);

    const onPreviousImage = useCallback(
        () => changeImage(imageIndex - 1 < 0 ? images.length - 1 : imageIndex - 1),
        [images, changeImage, imageIndex]
    );
    const onNextImage = useCallback(
        () => changeImage(imageIndex + 1 >= images.length ? 0 : imageIndex + 1),
        [images, changeImage, imageIndex]
    );

    return {
        imageIndex,
        imageTransition,
        onPreviousImage,
        onNextImage
    };
};

export const useLargeViewZoomControls = (imageRef, imageIndex) => {
    const [imageZoom, setImageZoom] = useState(1);

    useEffect(() => setImageZoom(1), [imageIndex]); // Reset image zoom when image changes

    useEffect(() => {
        const imageElement = imageRef.current;
        if (imageElement && imageElement.offsetWidth > 0) {

            // Clear any image changes so that the width and height calculations are precise
            imageElement.classList.remove('zoomed');
            imageElement.removeAttribute('style');

            // Get image width and height
            const imageWidth = imageElement.offsetWidth;
            const imageHeight = imageElement.offsetHeight;

            // Do the actual zoom
            imageElement.style.width = (imageWidth * imageZoom) + 'px';
            imageElement.style.height = (imageHeight * imageZoom) + 'px';

            if (imageZoom === 1) {
                imageElement.classList.remove('zoomed');
                imageElement.removeAttribute('style');
            } else {

                // Add the "zoomed" class which removes the max-width and max-height constraints
                imageElement.classList.add('zoomed');
            }
        }
    }, [imageRef, imageZoom, imageIndex]);

    const zoomImage = useCallback(operation => {
        if (operation === 'in' && imageZoom < 2) {
            setImageZoom(imageZoom + 0.5);
        } else if (operation === 'out' && imageZoom > 1) {
            setImageZoom(imageZoom - 0.5);
        }
    }, [imageZoom]);

    const onZoomIn = useCallback(() => zoomImage('in'), [zoomImage]);
    const onZoomOut = useCallback(() => zoomImage('out'), [zoomImage]);

    return {
        onZoomIn,
        onZoomOut
    }
};