import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import './el-items-list.css';

const ElItemsList = ({ items, onEditItem, onRemoveItem }) => {
  return (
    <ul className="el-items-list">
      {items.map((item) => (
        <li key={item.id} className="el-items-list__item">
          <h3>{item.name}</h3>
          <div className="el-items-list__item-actions">
            <IconButton onClick={() => onEditItem(item)}>
              <EditIcon fontSize="large" />
            </IconButton>
            <IconButton onClick={() => onRemoveItem(item)}>
              <DeleteIcon fontSize="large" />
            </IconButton>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ElItemsList;
