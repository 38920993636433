import { useReducer } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { initialState, reducer } from './evaluare-lucrari.store';

import ElClassesPage from './el-classes-page/el-classes-page';
import ElTestsPage from './el-tests-page/el-tests-page';

import './common/styles/global.css';
import './evaluare-lucrari.css';

const EvaluareLucrari = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  let { path } = useRouteMatch();

  return (
    <main className="app-wrapper evaluare-lucrari">
      <h1>Evaluare lucrari</h1>

      <Switch>
        <Route path={`${path}/`}>
          <ElClassesPage classes={state.classes} dispatch={dispatch} />
        </Route>
        <Route path={`${path}/tests`}>
          <ElTestsPage tests={state.tests} dispatch={dispatch} />
        </Route>
        <Route>
          <Redirect to={{ pathname: `${path}/` }} />
        </Route>
      </Switch>
    </main>
  );
};

export default EvaluareLucrari;
