import './about.css';

const About = () => {
  return (
    <section id="about-me" className="section about">
      <div className="section__floating-bg about__floating-bg"></div>
      <div className="section__content">
        <h2>About me</h2>
        <p>
          I'm an enthusiastic web developer with{' '}
          <strong>
            over 12 years of experience in web and hybrid mobile apps.
          </strong>
        </p>
        <p>
          I mostly worked with{' '}
          <strong>React, Angular, Node.js, SQL, MongoDB and WordPress</strong>{' '}
          on some sites.
        </p>
        <p>
          I've been in <strong>teams of different sizes and cultures</strong>{' '}
          and had various roles in{' '}
          <strong>
            development, project architecture, infrastructure setup and also
            held job interviews.
          </strong>
        </p>
        <p>
          <a className="button go-to-cv" href="#cv">
            Check out my CV
          </a>
          <a className="button" href="#portfolio">
            Check out my Portfolio
          </a>
        </p>
      </div>
    </section>
  );
};

export default About;
