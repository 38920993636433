import { useState, useEffect } from 'react';

export const useAdjustNumberOfSlides = verticalImages => {
    const [numberOfSlides, setNumberOfSlides] = useState(2);

    useEffect(() => {
        const onScreenResize = () => {
            if (document.body.offsetWidth < 500) {
                setNumberOfSlides(1);
            } else if (document.body.offsetWidth < 1200) {
                setNumberOfSlides(verticalImages ? 2 : 1);
            } else if (document.body.offsetWidth < 1850) {
                setNumberOfSlides(verticalImages ? 3 : 2);
            } else {
                setNumberOfSlides(verticalImages ? 4 : 3);
            }
        };

        onScreenResize();
        window.addEventListener('resize', onScreenResize);
        return () => window.removeEventListener('resize', onScreenResize);
    }, [verticalImages]);

    return numberOfSlides;
};