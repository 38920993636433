import { useState, useEffect } from 'react';

export const useCarouselOffsetRadius = () => {
    const [offsetRadius, setOffsetRadius] = useState(1);

    useEffect(() => {
        const onScreenResize = () => {
            if (document.body.offsetWidth < 768) {
                setOffsetRadius(0);
            } else if (document.body.offsetWidth > 1850) {
                setOffsetRadius(2);
            } else {
                setOffsetRadius(1);
            }
        };

        onScreenResize();
        window.addEventListener('resize', onScreenResize);
        return () => window.removeEventListener('resize', onScreenResize);
    }, []);

    return offsetRadius;
};

export const useSlides = portfolioSlides => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState(portfolioSlides);

    useEffect(() => {
        setSlides(
            slides => slides.map((slide, i) => ({
                ...slide,
                content: (
                    <div className="portfolio__carousel-slide" onClick={() => setCurrentSlide(i)}>
                        { slide.content }
                    </div>
                )
            }))
        );
    }, []);

    const nextSlide = () => setCurrentSlide(currentSlide + 1);
    const previousSlide = () => setCurrentSlide(currentSlide - 1);

    return {
        slides,
        currentSlide,
        nextSlide,
        previousSlide
    };
};