import { useState } from 'react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { Actions } from './../evaluare-lucrari.store';

import ElTestDialog from './../components/el-test-dialog/el-test-dialog';
import ElItemsList from './../components/el-items-list/el-items-list';

const ElTestsPage = ({ tests, dispatch }) => {
  const [isTestDialogOpen, setIsTestDialogOpen] = useState(false);
  const [testToEdit, setTestToEdit] = useState(null);

  const onShowTestAddDialog = () => {
    setIsTestDialogOpen(true);
    setTestToEdit(null);
  };

  const onShowTestEditDialog = (test) => {
    setIsTestDialogOpen(true);
    setTestToEdit(test);
  };

  const onRemoveTest = (test) => {
    if (window.confirm(`Sigur doriți să ștergeți testul "${test.name}"?`)) {
      dispatch(Actions.removeTest(test.id));
    }
  };

  const onCloseTestModal = (testName) => {
    if (testName) {
      if (testToEdit) {
        dispatch(Actions.editTest(testToEdit.id, testName));
      } else {
        dispatch(Actions.createNewTest(testName));
      }
    }

    setTestToEdit(null);
    setIsTestDialogOpen(false);
  };

  return (
    <div className="page el-tests-page">
      {tests.length > 0 ? (
        <ElItemsList
          items={tests}
          onEditItem={onShowTestEditDialog}
          onRemoveItem={onRemoveTest}
        />
      ) : (
        <p>Nu există nici o lucrare</p>
      )}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={onShowTestAddDialog}
      >
        Adaugă o lucrare nouă
      </Button>

      <ElTestDialog
        isOpen={isTestDialogOpen}
        testToEdit={testToEdit}
        onClose={onCloseTestModal}
      ></ElTestDialog>
    </div>
  );
};

export default ElTestsPage;
