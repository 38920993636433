import { useEffect } from 'react';

export const useLoadAnimations = () => {
    useEffect(() => {
        let scrollTimeout = null;
        let cvAnimationsDone = false;
        let portfolioAnimationsDone = false;

        const root = document.getElementById('root');
        const callToAction = document.querySelector('.call-to-action');
        const aboutSection = document.querySelector('.section.about');
        const cvSection = document.querySelector('.section.cv');
        const portfolioSection = document.querySelector('.section.portfolio');
        const cvButtons = document.querySelector('.cv__view-options');
        const skillBars = document.querySelectorAll('.progress-bar__fill.force-animation');

        const animationsBreakpointOffset = window.innerWidth < 1000 ? 500 : 200;

        const applyCvInnerAnimations = () => {
            cvButtons.classList.add('shown');
            skillBars.forEach(skillBar => skillBar.classList.remove('force-animation'));
            cvAnimationsDone = true;
        }

        const displaySections = () => {
            callToAction.classList.add('fade-in');
            aboutSection.classList.add('shown');
            cvSection.classList.add('shown');

            if (window.innerWidth < 1000) {
                portfolioSection.classList.add('shown');
            }
            
            if (window.innerWidth > 1349) {
                applyCvInnerAnimations();
            }
        };

        const animateSections = e => {
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
                scrollTimeout = null;
            }

            scrollTimeout = setTimeout(() => { // Add 15 miliseconds of debounce for better performance
                const scrollTop = e.target.scrollTop;

                if (
                    window.innerWidth > 999 && 
                    !portfolioAnimationsDone &&
                    scrollTop >= portfolioSection.offsetTop - animationsBreakpointOffset
                ) {
                    portfolioSection.classList.add('shown');
                    portfolioAnimationsDone = true;
                }

                if (!cvAnimationsDone && scrollTop >= cvSection.offsetTop - animationsBreakpointOffset) {
                    applyCvInnerAnimations();
                }
            }, 15);
        };

        if (window.innerWidth < 1000) {
            displaySections();
        } else {
            setTimeout(displaySections, 10); 
        }

        root.addEventListener('scroll', animateSections);
        return () => root.removeEventListener('scroll', animateSections);
    }, []);
};