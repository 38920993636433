import Carousel from 'react-spring-3d-carousel';

import { useCarouselOffsetRadius, useSlides } from './portfolio.effects';

import './portfolio.css';

import portfolioSlides from './portfolio-slides';


const Portfolio = () => {
    const { slides, currentSlide, nextSlide, previousSlide } = useSlides(portfolioSlides);
    const offsetRadius = useCarouselOffsetRadius();

    return (
        <section id="portfolio" className="section portfolio">
            <div className="section__floating-bg portfolio__floating-bg"></div>
            <div className="section__content">
                <h2>Portfolio</h2>
                <p className="portfolio__intro">Take a look at some of the projects I've worked on over the years:</p>
                { offsetRadius > 0 ?
                    (<div className="portfolio__carousel">
                        <Carousel 
                            showNavigation={false}
                            slides={slides}
                            goToSlide={currentSlide}
                            offsetRadius={offsetRadius}
                        />
                        <div className="portfolio__carousel-nav">
                            <button className="carousel-previous-button" onClick={previousSlide}><i className="fa fa-chevron-left"></i></button>
                            <button className="carousel-next-button" onClick={nextSlide}><i className="fa fa-chevron-right"></i></button>
                        </div>
                    </div>)

                    :

                    slides.map(slide => {
                        return <div key={slide.key}>{ slide.content }</div>;
                    })
                }
            </div>
        </section>
    );
};

export default Portfolio;