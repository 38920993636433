export const isMobile = () => document.body.offsetWidth < 768;


// Local storage JSON save and retrieve

export const saveObjectToLocalStorage = (fieldName, objectToSave) => {
    try {
        const objectAsJson = JSON.stringify(objectToSave);
        localStorage.setItem(fieldName, objectAsJson);
    } catch (error) {
        console.error('Invalid JSON: ' + error.message);
    }
};

export const getObjectFromLocalStorage = fieldName => {
    try {
        const jsonObject = localStorage.getItem(fieldName);
        return JSON.parse(jsonObject);
    } catch (error) {
        console.error('Couldn\'t retrieve local storage data: ' + error.message);
        return null;
    }
}