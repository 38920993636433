import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

import MainWebsite from './../pages/main-website/main-website';
import Apps from './../pages/apps/apps';

import './root.css';


const Root = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <MainWebsite />
      </Route>
      <Route path="/apps">
        <Apps />
      </Route>
      <Route>
        <Redirect to={{ pathname: '/' }} />
      </Route>
    </Switch>
  </Router>
);

export default Root;
