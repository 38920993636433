import { v4 as uuidv4 } from 'uuid';

const ActionName = {
  ADD_CLASS: 'add-class',
  EDIT_CLASS: 'edit-class',
  REMOVE_CLASS: 'remove-class',
  ADD_STUDENT: 'add-student',
  EDIT_STUDENT: 'edit-student',
  REMOVE_STUDENT: 'remove-student',
  ADD_TEST: 'add-test',
  EDIT_TEST: 'edit-test',
  REMOVE_TEST: 'remove-test',
  ADD_TEST_ITEM: 'add-test-item',
  EDIT_TEST_ITEM: 'edit-test-item',
  REMOVE_TEST_ITEM: 'remove-test-item',
};

const createNewTest = (name, classId) => ({
  id: 'test-' + uuidv4(),
  name,
  items: [],
});

const createNewClass = (name) => ({
  id: 'class-' + uuidv4(),
  name
});

const editStateEntry = (allItems, payload) => {
  const editedItemId = payload.id;
  const itemIndex = allItems.findIndex((item) => item.id === editedItemId);

  if (itemIndex > -1) {
    const newItems = [...allItems];
    newItems[itemIndex] = { ...newItems[itemIndex], ...payload };
    return newItems;
  } else {
    return allItems;
  }
};

const removeStateEntry = (allItems, itemId) => {
  const itemToRemoveIndex = allItems.findIndex((item) => item.id === itemId);

  if (itemToRemoveIndex > -1) {
    const newItems = [ ...allItems ];
    newItems.splice(itemToRemoveIndex, 1);
    return newItems;
  } else {
    return allItems;
  }
};

const addTestItemInState = (tests, testId, newItem) => {
  const testIndex = tests.findIndex((test) => test.id === testId);

  if (testIndex > -1) {
    const newTests = [...tests];
    newTests[testIndex].items = [
      ...newTests.items,
      {
        id: uuidv4(),
        ...newItem,
      },
    ];
    return newTests;
  } else {
    return tests;
  }
};

const editTestItemInState = (tests, testId, item) => {
  const testIndex = tests.findIndex((test) => test.id === testId);

  if (testIndex > -1) {
    const itemIndex = tests[testIndex].items.findIndex(
      (testItem) => testItem.id === item.id
    );

    if (itemIndex > -1) {
      const newTests = [...tests];
      newTests[testIndex].items[itemIndex] = { ...item };
    } else {
      return tests;
    }
  } else {
    return tests;
  }
};

const removeTestItemFromState = (tests, testId, itemId) => {
  const testIndex = tests.findIndex((test) => test.id === testId);

  if (testIndex > -1) {
    const itemIndex = tests[testIndex].items.findIndex(
      (testItem) => testItem.id === itemId
    );

    if (itemIndex > -1) {
      const newTests = [...tests];
      const newTestItems = [...newTests[testIndex].items];
      newTestItems.splice(itemIndex, 1);
      newTests[testIndex].items = newTestItems;
    } else {
      return tests;
    }
  } else {
    return tests;
  }
};


// INITIAL STATE, REDUCER AND ACTIONS

export const initialState = {
  classes: [],
  students: [],
  tests: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    // Class add, edit or delete operations
    case ActionName.ADD_CLASS:
      return {
        ...state,
        classes: [...state.classes, createNewClass(action.payload)],
      };
    case ActionName.EDIT_CLASS:
      return {
        ...state,
        classes: editStateEntry(state.classes, action.payload)
      };
    case ActionName.REMOVE_CLASS:
      return {
        ...state,
        classes: removeStateEntry(state.classes, action.payload)
      };

    // Test add, edit or delete operations 
    case ActionName.ADD_TEST:
      return {
        ...state,
        tests: [...state.tests, createNewTest(action.payload)],
      };
    case ActionName.EDIT_TEST:
      return {
        ...state,
        tests: editStateEntry(state.tests, action.payload),
      };
    case ActionName.REMOVE_TEST:
      return {
        ...state,
        tests: removeStateEntry(state.tests, action.payload),
      };

    // Test questions and exercices
    case ActionName.ADD_TEST_ITEM:
      return {
        ...state,
        tests: addTestItemInState(
          state.tests,
          action.payload.testId,
          action.payload.item
        ),
      };
    case ActionName.EDIT_TEST_ITEM:
      return {
        ...state,
        test: editTestItemInState(
          state.tests,
          action.payload.testId,
          action.payload.item
        ),
      };
    case ActionName.REMOVE_TEST_ITEM:
      return {
        ...state,
        tests: removeTestItemFromState(
          state.tests,
          action.payload.testId,
          action.payload.itemId
        ),
      };

    default:
      return state;
  }
};

export const Actions = {
  createNewClass: (name) => ({ type: ActionName.ADD_CLASS, payload: name }),
  editClass: (id, name) => ({
    type: ActionName.EDIT_CLASS,
    payload: { id, name },
  }),
  removeClass: (id) => ({ type: ActionName.REMOVE_CLASS, payload: id }),

  createNewTest: (name) => ({ type: ActionName.ADD_TEST, payload: name }),
  editTest: (id, name, classId) => ({
    type: ActionName.EDIT_TEST,
    payload: { id, name, classId },
  }),
  removeTest: (id) => ({ type: ActionName.REMOVE_TEST, payload: id }),
};
